

















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class EmptyTips extends Vue {
    @Prop({ default: false })
    showLock
    @Prop()
    title
    @Prop()
    desc
}
