var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "devops-empty-tips" }, [
    _vm.showLock
      ? _c("p", [_c("icon", { attrs: { name: "locked", size: "52" } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _c("h2", { staticClass: "title" }, [
      _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "desc" }, [
      _vm._v("\n        " + _vm._s(_vm.desc) + "\n    ")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "btns-row" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }