

















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
@Component({
    name: 'devops-option'
})
export default class DevopsOption extends Vue {
    @Prop({ required: true })
    id: string | number
    @Prop({ required: true })
    name: string | number
    @Prop()
    multiple: boolean
    @Prop()
    isSelected: boolean
    @Prop()
    disabled: boolean
}
