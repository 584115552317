var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bk-option-content" },
    [
      _vm._t("default", [
        _c(
          "div",
          {
            staticClass: "bk-option-content-default",
            attrs: { title: _vm.name }
          },
          [
            _c("span", { staticClass: "bk-option-name" }, [
              _vm._v(_vm._s(_vm.name))
            ]),
            _vm._v(" "),
            _vm.multiple && _vm.isSelected
              ? _c("i", {
                  staticClass: "bk-option-icon devops-icon icon-check-1"
                })
              : _vm._e()
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }