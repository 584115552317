var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "bk-navigation",
    {
      staticClass: "bkdevops-aside-nav",
      attrs: {
        "theme-color": _vm.navTheme.themeColor,
        "default-open": true,
        "header-title": _vm.headerTitle
      }
    },
    [
      _c(
        "template",
        { slot: "side-header" },
        [
          _c("logo", {
            staticClass: "title-icon",
            attrs: { name: _vm.nav.icon, size: "32" }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "title-desc" }, [
            _vm._v(_vm._s(_vm.nav.title))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "header-right-set",
          attrs: { slot: "header-set" },
          slot: "header-set"
        },
        [_vm._t("header-right")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "menu" }, slot: "menu" },
        [
          _c(
            "bk-navigation-menu",
            _vm._b(
              {
                ref: "menu",
                attrs: {
                  "default-active": _vm.$route.name,
                  "toggle-active": _vm.nav.toggle
                },
                on: { select: function() {} }
              },
              "bk-navigation-menu",
              _vm.navTheme,
              false
            ),
            _vm._l(_vm.nav.menu, function(item) {
              return _c(
                "bk-navigation-menu-item",
                _vm._b(
                  { key: item.id, on: { click: _vm.menuClick } },
                  "bk-navigation-menu-item",
                  item,
                  false
                ),
                [_c("span", [_vm._v(_vm._s(item.name))])]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }