var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "bk-popover",
        {
          ref: "showPopover",
          staticClass: "show-tooltip",
          attrs: { theme: "light", placement: _vm.placement, always: "isShow" }
        },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "show-tooltip-content",
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm._v("\n        " + _vm._s(_vm.content) + "\n        "),
              _vm.content
                ? _c("div", { staticClass: "show-tooltip-footer" }, [
                    _c(
                      "span",
                      {
                        staticClass: "close-tooltip-btn",
                        on: { click: _vm.confirmBtn }
                      },
                      [_vm._v(_vm._s(_vm.footer))]
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        2
      )
    : _c("div", { staticClass: "show-tooltip" }, [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }