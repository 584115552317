var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "bk-form-item",
    _vm._b(
      { class: { "is-error": _vm.isError } },
      "bk-form-item",
      _vm.$attrs,
      false
    ),
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.isError
        ? _vm._t("error-tips", [
            _c("div", { staticClass: "error-tips" }, [
              _vm._v("\n            " + _vm._s(_vm.errorMsg) + "\n        ")
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }