

































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { BkPopover } from 'bk-magic-vue'

@Component
export default class ShowTooltip extends Vue {
    @Prop({ required: true })
    name: string
    @Prop({
        default: 'bottom',
        validator (value) {
            return ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'].indexOf(value) !== -1
        }
    })
    placement: string

    @Prop({ default: '' })
    content: string

    @Prop({ default: '知道了' })
    footer: string

    @Prop({ default: '0' })
    margin: string

    @Prop({ default: 230 })
    width: number

    @Prop({ default: false })
    always: boolean

    isShow: boolean = false

    $refs = {
        showPopover: BkPopover
    }

    get instance () {
        return this.$refs.showPopover && this.$refs.showPopover.instance
    }

    created () {
        const tooltipEventList = this.getTooltipEventList()
        this.isShow = tooltipEventList.indexOf(this.name) < 0
    }

    beforeDestroy () {
        if (this.instance && typeof this.instance.hide === 'function') {
            this.instance.hide()
        }
    }

    getTooltipEventList () {
        const tooltipEventList = localStorage.getItem('tooltipEventList')
        return tooltipEventList ? JSON.parse(tooltipEventList) : []
    }

    confirmBtn () {
        const tooltipEventListString: string = localStorage.getItem('tooltipEventList')
        const tooltipEventList: string[] = tooltipEventListString ? JSON.parse(tooltipEventListString) : []
        tooltipEventList.push(this.name)

        localStorage.setItem('tooltipEventList', JSON.stringify(tooltipEventList))
        this.isShow = false
        if (this.instance && typeof this.instance.hide === 'function') {
            this.instance.hide()
        }
        this.$emit('confirm')
    }
}
