










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class SvgIcon extends Vue {
    @Prop()
    name
    @Prop({ default: 32 })
    size
}
