var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bk-select",
      class: {
        "is-focus": _vm.focus,
        "is-disabled": _vm.disabled,
        "is-readonly": _vm.readonly,
        "is-loading": _vm.loading,
        "is-unselected": _vm.isUnselected,
        "is-default-trigger": !_vm.$scopedSlots.trigger
      },
      attrs: { "data-placeholder": _vm.localPlaceholder }
    },
    [
      !_vm.$scopedSlots.trigger
        ? [
            _vm.loading
              ? [
                  _c("icon", {
                    staticClass: "bk-select-loading spin-icon",
                    attrs: { name: "circle-2-1" }
                  })
                ]
              : [
                  _vm.clearable &&
                  !_vm.isUnselected &&
                  !_vm.disabled &&
                  !_vm.readonly
                    ? _c("i", {
                        staticClass:
                          "bk-select-clear bk-icon icon-close-circle-shape",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.reset($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "bk-select-angle bk-icon icon-angle-down"
                  })
                ]
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "bk-popover",
        {
          ref: "selectDropdown",
          staticClass: "bk-select-dropdown",
          attrs: {
            trigger: "click",
            placement: "bottom-start",
            theme: "light bk-select-dropdown",
            animation: "slide-toggle",
            offset: -1,
            distance: 16,
            "on-show": _vm.handleDropdownShow,
            "on-hide": _vm.handleDropdownHide,
            "tippy-options": _vm.popoverOptions
          }
        },
        [
          _vm._t(
            "trigger",
            [
              _c(
                "div",
                {
                  staticClass: "bk-select-name",
                  attrs: { title: _vm.selectedName }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.selectedName) +
                      "\n            "
                  )
                ]
              )
            ],
            null,
            _vm.$props
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "bkloading",
                  rawName: "v-bkloading",
                  value: { isLoading: _vm.remoteSearchLoading },
                  expression: "{ isLoading: remoteSearchLoading }"
                }
              ],
              staticClass: "bk-select-dropdown-content",
              style: _vm.popoverStyle,
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm.searchable
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchValue,
                        expression: "searchValue"
                      }
                    ],
                    ref: "searchInput",
                    staticClass: "bk-select-search-input",
                    attrs: { type: "text", placeholder: _vm.localPlaceholder },
                    domProps: { value: _vm.searchValue },
                    on: {
                      keydown: function($event) {
                        return _vm.handleKeydown($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchValue = $event.target.value
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                {
                  ref: "optionList",
                  staticClass: "bk-options",
                  class: {
                    "bk-options-single": !_vm.multiple
                  },
                  style: {
                    maxHeight: _vm.scrollHeight + "px"
                  }
                },
                _vm._l(_vm.filterOptions, function(option, index) {
                  return _c(
                    "li",
                    {
                      key: option.id,
                      staticClass: "bk-option",
                      class: {
                        "is-selected": _vm.isSelect(option),
                        "is-disabled": option.disabled,
                        "is-highlight": index === _vm.highlightIndex
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleOptionClick(option)
                        }
                      }
                    },
                    [
                      _vm._t(
                        "option",
                        [
                          _c(
                            "devops-option",
                            _vm._b(
                              {
                                attrs: {
                                  "is-selected": _vm.isSelect(option),
                                  multiple: _vm.multiple
                                }
                              },
                              "devops-option",
                              option,
                              false
                            )
                          )
                        ],
                        null,
                        _vm.slotOption(option)
                      )
                    ],
                    2
                  )
                }),
                0
              ),
              _vm._v(" "),
              !_vm.options.length
                ? _c("div", { staticClass: "bk-select-empty" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.emptyText) +
                        "\n            "
                    )
                  ])
                : _vm.searchable && _vm.unmatchedCount === _vm.options.length
                ? _c("div", { staticClass: "bk-select-empty" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.emptyText) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.extension
                ? _c(
                    "div",
                    { staticClass: "bk-select-extension" },
                    [_vm._t("extension")],
                    2
                  )
                : _vm._e()
            ]
          )
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }