

















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DevopsFormItem extends Vue {
    @Prop()
    errorMsg: string = ''
    @Prop()
    isError: boolean = false
}
