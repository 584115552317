












































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class AsideNav extends Vue {
    @Prop({ default: '' })
    headerTitle: string
    @Prop({ required: true })
    nav: Object

    @Prop({ required: true })
    menuClick
    
    get navTheme () {
        return {
            themeColor: 'white',
            itemDefaultBgColor: 'white',
            itemActiveBgColor: '#ebf4ff',
            itemHoverBgColor: '#ebf4ff',
            itemHoverColor: '#3c96ff',
            itemActiveColor: '#3c96ff',
            itemDefaultColor: '#7b7d8a'
        }
    }
}
