var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "bk-dialog",
    {
      attrs: {
        "ext-cls": "devops-announcement-dialog",
        "ok-text": _vm.$t("expNow"),
        width: 828,
        "close-icon": false,
        position: { top: "100" },
        title: _vm.currentNotice.noticeTitle
      },
      on: {
        confirm: function($event) {
          return _vm.toLink(_vm.currentNotice.redirectUrl)
        }
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("main", { staticClass: "new-service-content" }, [
        _c("div", { staticClass: "announcement-content" }, [
          _c("div", {
            staticClass: "content-detail",
            domProps: { innerHTML: _vm._s(_vm.currentNotice.noticeContent) }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }