var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { fill: "currentColor" },
      attrs: { width: _vm.size, height: _vm.size }
    },
    [
      _c(
        "use",
        _vm._b({}, "use", { "xlink:href": "#color-logo-" + _vm.name }, false)
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }