





















import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
@Component
export default class NewServiceDialog extends Vue {
    @State currentNotice
    showDialog: boolean = false

    get announcementHistory () : object[] {
        const announcementHistory = localStorage.getItem('announcementHistory')
        return announcementHistory ? JSON.parse(announcementHistory) : []
    }
    @Watch('currentNotice')
    handleWatchValue (currentNotice) {
        this.init(currentNotice)
    }
    mounted () {
        this.init(this.currentNotice)
    }
    init (currentNotice) {
        if (currentNotice && currentNotice.id && currentNotice.noticeType === 0 && this.announcementHistory.indexOf(currentNotice.id) === -1) {
            this.announcementHistory.push(currentNotice.id)
            localStorage.setItem('announcementHistory', JSON.stringify(this.announcementHistory))
            this.showDialog = true
        }
    }
    toLink (url) {
        if (url) {
            window.location.href = url
        } else {
            this.showDialog = false
        }
    }
    closeDialog () {
        this.showDialog = false
    }
}
